import React, { useState, useEffect } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";

const navigation = [
  { name: "Home", href: "/" },
  { name: "Products" },
  { name: "Solutions" },
  { name: "About Us", href: "/about-us" },
  { name: "Connect", href: "/connect" },
];

const solutionsDropdown = [
  { name: "Education", href: "/solutions/education" },
  { name: "Corporate", href: "/solutions/corporate" },
];

const productsDropdown = [{ name: "Interactive Flat Panel" }];

const interactiveFlatPanelDropdown = [
  {
    name: "Evoke Series",
    href: "/products/interactive-flat-panel/evoke-series",
  },
  { name: "Rise Series", href: "/products/interactive-flat-panel/rise-series" },
  {
    name: "Inspire Series",
    href: "/products/interactive-flat-panel/inspire-series",
  },
];

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [openMenu, setOpenMenu] = useState(null); // This state controls which menu is open (Products or Solutions, etc.)
  const [interactiveDropdownOpen, setInteractiveDropdownOpen] = useState(false); // Sub-dropdown inside Products
  const location = useLocation();

  // Function to handle opening and closing of menus
  const handleMenuClick = (menuName) => {
    // Toggle open/close only for the clicked menu; close all others
    setOpenMenu(openMenu === menuName ? null : menuName);
    setInteractiveDropdownOpen(false); // Close sub-dropdown when another menu is toggled
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsFixed(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  const handleInteractiveClick = () => {
    setInteractiveDropdownOpen(!interactiveDropdownOpen);
  };

  return (
    <>
      <header
        className={`inset-x-0 top-0 z-50 border-white/30 bg-gradient-radial backdrop-blur-md border-b ${
          isFixed ? "fixed fadeInDown bg-white fixed-nav" : "absolute nav-text"
        }`}
      >
        <nav
          aria-label="Global"
          className="flex items-center lg:justify-center justify-between px-5 lg:px-8 max-w-7xl m-auto"
        >
          <div className="flex lg:flex-1 py-3.5">
            <Link to="/">
              <img
                alt="Logo"
                src={
                  isFixed
                    ? "/assets/images/coltec-logo-black.svg"
                    : "/assets/images/coltec-logo-white.svg"
                }
                className="h-7 w-auto"
              />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className={`-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 ${
                isFixed ? "text-gray-400" : "text-white"
              }`}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-8 w-8" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) =>
              item.name === "Solutions" ? (
                <div key={item.name} className="relative">
                  <button
                    onClick={() => handleMenuClick("solutions")}
                    className="text-sm transition-all link font-medium leading-6 text-gray-950"
                  >
                    {item.name}
                  </button>
                  {openMenu === "solutions" && (
                    <div className="absolute left-0 right-0 mt-5 w-36 bg-white shadow-lg rounded-md ring-1 ring-gray-900/5 fadeInDown">
                      <div className="py-1 dropdown-link">
                        {solutionsDropdown.map((dropdownItem) => (
                          <Link
                            key={dropdownItem.name}
                            to={dropdownItem.href}
                            className="block px-4 py-2 text-sm text-gray-950 hover:bg-sky-100 link font-medium"
                          >
                            {dropdownItem.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ) : item.name === "Products" ? (
                <div key={item.name} className="relative">
                  <button
                    onClick={() => handleMenuClick("products")}
                    className="text-sm transition-all link font-medium leading-6 text-gray-950"
                  >
                    {item.name}
                  </button>
                  {openMenu === "products" && (
                    <div className="absolute left-0 right-0 mt-5 w-48 bg-white shadow-lg rounded-md ring-1 ring-gray-900/5 fadeInDown">
                      <div className="py-1 dropdown-link">
                        {productsDropdown.map((product) =>
                          product.name === "Interactive Flat Panel" ? (
                            <div key={product.name} className="relative">
                              <button
                                onClick={handleInteractiveClick}
                                className="block relative px-4 py-2 text-sm text-gray-950 hover:bg-sky-100 link font-medium"
                              >
                                {product.name} <img alt="arrow-right" src="/assets/images/chevron-right.svg" className="chevron-right lg:inline-block hidden absolute top-[10px] ml-[5px] w-[15px]"/>
                              </button>
                              {interactiveDropdownOpen && (
                                <div className="absolute left-full top-0 -mt-1 ml-[2px] w-36 bg-white shadow-lg rounded-md ring-1 ring-gray-900/5 fadeInDown">
                                  <div className="py-1">
                                    {interactiveFlatPanelDropdown.map(
                                      (item) => (
                                        <Link
                                          key={item.name}
                                          to={item.href}
                                          className="block px-4 py-2 text-sm text-gray-950 hover:bg-sky-100 link font-medium"
                                        >
                                          {item.name}
                                        </Link>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <Link
                              key={product.name}
                              to={product.href}
                              className="block px-4 py-2 text-sm text-gray-950 hover:bg-sky-100 link font-medium"
                            >
                              {product.name}
                            </Link>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-sm transition-all link font-medium leading-6 text-gray-950"
                >
                  {item.name}
                </Link>
              )
            )}
          </div>
        </nav>

        {/* Mobile Menu */}
        <Dialog
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          className="lg:hidden"
        >
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                <img
                  alt="Logo"
                  src={isFixed ? "/assets/images/coltec-logo-black.svg" : "/assets/images/coltec-logo-black.svg"}
                  className="h-7 w-auto"
                />
              </Link>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-400"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-8 w-8" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/25">
                <div className="flex flex-col py-6 space-y-2">
                  {navigation.map((item) =>
                    item.name === "Solutions" ? (
                      <div key={item.name} className="relative">
                        <button
                          onClick={() => handleMenuClick("mobile-solutions")}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base font-medium leading-7 text-black"
                        >
                          {item.name}
                        </button>
                        {openMenu === "mobile-solutions" && (
                          <div className="mt-2 space-y-2">
                            {solutionsDropdown.map((dropdownItem) => (
                              <Link
                                key={dropdownItem.name}
                                to={dropdownItem.href}
                                className="block rounded-lg px-3 py-2 text-base font-medium leading-7 text-black"
                              >
                                {dropdownItem.name}
                              </Link>
                            ))}
                          </div>
                        )}
                      </div>
                    ) : item.name === "Products" ? (
                      <div key={item.name} className="relative">
                        <button
                          onClick={() => handleMenuClick("mobile-products")}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base font-medium leading-7 text-black"
                        >
                          {item.name}
                        </button>
                        {openMenu === "mobile-products" && (
                          <div className="mt-2 space-y-2">
                            {productsDropdown.map((product) =>
                              product.name === "Interactive Flat Panel" ? (
                                <div key={product.name} className="relative">
                                  <button
                                    onClick={handleInteractiveClick}
                                    className="block rounded-lg px-3 py-2 text-base font-medium leading-7 text-black"
                                  >
                                    {product.name}
                                  </button>
                                  {interactiveDropdownOpen && (
                                    <div className="ml-4 space-y-2">
                                      {interactiveFlatPanelDropdown.map(
                                        (item) => (
                                          <Link
                                            key={item.name}
                                            to={item.href}
                                            className="block rounded-lg px-3 py-2 text-base font-medium leading-7 text-black"
                                          >
                                            {item.name}
                                          </Link>
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <Link
                                  key={product.name}
                                  to={product.href}
                                  className="block rounded-lg px-3 py-2 text-base font-medium leading-7 text-black"
                                >
                                  {product.name}
                                </Link>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-medium leading-7 text-black"
                      >
                        {item.name}
                      </Link>
                    )
                  )}
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
    </>
  );
};

export default Header;
